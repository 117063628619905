import logo from "./logo.svg";
import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import configureStore from "./state/store/configureStore";

import { Home } from "./Components/Home";
const store = configureStore();

export const App = () => {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Home />
      </Provider>
    </React.Fragment>
  );
};
