import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";

export const SectionNine = () => {
  const [datafromclinical, setDatafromclinical] = useState("");
  const [concomitantdiseases, setConcomitantdiseases] = useState("");
  const [forcongenitalabnormality, setForcongenitalabnormality] = useState("");

  return (
    <React.Fragment>
      <li class="form-line">
        <div className="form-title">
          <h1>Relevant additonal information</h1>
        </div>

        <Row className="">
          <Col md="12">
            <div className="form-group">
              <div className="form-check form-check-inline">
                <label className="form-check-label" for="inlineCheckbox20">
                  Data from clinical,laboratory,X-ray investigations,and
                  autopsy,including determinations of durg concentration in the
                  blood/tissues,if there are any and if they are associated with
                  the ADR(please indicate dates).
                </label>
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-check form-check-inline">
                <label className="form-check-label" for="inlineCheckbox20">
                  Concomitant diseases.History data,suspected drug interactions.
                </label>
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="form-group">
              <div className="form-check form-check-inline">
                <label className="form-check-label" for="inlineCheckbox20">
                  For congenital abnormality,specify all other medicinal
                  products taken during pregnancy and the date of last
                  period.Please attach additonal pages if necessary.
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};
