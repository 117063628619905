import React, { useState, useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import { StackedForm } from "./StackedForm";

export const Home = () => {
  return (
    <React.Fragment>
      <StackedForm />
    </React.Fragment>
  );
};
