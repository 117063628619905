import React, { useState, useEffect } from "react";
import { Row, Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getGeneratedXMLfromJSON } from "../services/subscriberService";
import { getAllProducts } from "../state/selectors/products";

export const GenerateXMLFile = () => {
  const arrProfInfoData = useSelector(getAllProducts);
  const downloadTxtFile = (contentdata) => {
    const element = document.createElement("a");
    const file = new Blob([contentdata.data], {
      type: "xml/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "StakedForm.xml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const genrateXMLDataUI = async () => {
    const result = await getGeneratedXMLfromJSON(arrProfInfoData);
    downloadTxtFile(result);
    if (result) {
      console.log(result);
      console.log("Successfully send email to customer. Thank you.");
    }
  };

  const handleGeneratData = () => {
    genrateXMLDataUI();
  };
  return (
    <React.Fragment>
      <Button className="submit-btn" variant="primary" onClick={handleGeneratData}>
        SUBMIT
      </Button>
    </React.Fragment>
  );
};
