import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { useDispatch } from "react-redux";
import { setProducts } from "../state/actions/products";

export const SectionSeven = () => {
  const [outcome, setOutcome] = useState("");
  /////////////////////////////////////
  const [next, setNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      const arrData = [
        {
          OutCome: {
            outcome,
          },
        },
      ];
      dispatch(setProducts(arrData[0]));
      console.log("Dispatched Data====>", arrData[0]);
    }
    setNext(false);
  }, [next]);
  /////////////////////////////////////
  return (
    <React.Fragment>
      <li class="form-line">
        <div className="form-title">
          <h1>Outcome</h1>
        </div>

        <Row className="">
          <div className="col form-group">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions8" 
                    id="inlineRadio20"
                value="recovery without consequences"
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio20">
                recovery without consequences
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions8" 
                    id="inlineRadio21"
                value="death"
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio21">
                death
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions8" 
                id="inlineRadio22"
                value="condition improved"
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio22">
                condition improved
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions8" 
                    id="inlineRadio23"
                value="unknown"
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio23">
                unknown
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions8" 
                    id="inlineRadio24"
                value="condition without changes"
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineCheckbox24">
                condition without changes
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions8" 
                    id="inlineRadio25"
                value="not applicable"
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio25">
                not applicable
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions8" 
                id="inlineRadio26"
                value="recovery with consequences (specify)..."
                onChange={(e) => setOutcome(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio26">
                recovery with consequences (specify)...
              </label>
            </div>
          </div>
        </Row>
      </li>
    </React.Fragment>
  );
};
