import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { useDispatch } from "react-redux";
import { setProducts } from "../state/actions/products";

export const SectionTwo = () => {
  const [internalNonPropName, setInternalNonPropName] = useState("");
  const [tradeMe, setTradeMe] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [country, setCountry] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [indication, setIndication] = useState("");
  const [routOfAdministrator, setRoutOfAdministrator] = useState("");
  const [singleDose, setSingleDose] = useState("");
  const [startDateofTreatment, setStartDateofTreatment] = useState("");
  const [endDateOfTreatment, setEndDateOfTreatment] = useState("");
  const [drugCausedTheADR, setDrugCausedTheADR] = useState("");

  /////////////////////////////////////
  const [next, setNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      const arrData = [
        {
          MedicalProductNumber1: {
            internalNonPropName,
            tradeMe,
            manufacturer,
            country,
            batchNumber,
            indication,
            routOfAdministrator,
            singleDose,
            startDateofTreatment,
            endDateOfTreatment,
            drugCausedTheADR,
          },
        },
      ];
      dispatch(setProducts(arrData[0]));
      console.log("Dispatched Data====>", arrData[0]);
    }
    setNext(false);
  }, [next]);
  /////////////////////////////////////

  return (
    <React.Fragment>
      <li class="form-line">
        <div className="form-title">
          <h1>Medical Product Number 1 Presumably caused ADR</h1>
        </div>

        <Row className="">
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>International Non Properitey Name</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: Zeeshan Gazi"
                onChange={(e) => setInternalNonPropName(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Trade Me</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: ASDF"
                onChange={(e) => setTradeMe(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Manufacturer</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: Company Name"
                onChange={(e) => setManufacturer(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Country</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: India"
                onChange={(e) => setCountry(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Batch Number</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: 12345"
                onChange={(e) => setBatchNumber(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Indication</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder=""
                onChange={(e) => setIndication(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Rout of Administrator </label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder=""
                onChange={(e) => setRoutOfAdministrator(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Single Dose / Daily Dose</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder=""
                onChange={(e) => setSingleDose(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

          <Col md="6">
            <div className="formgroup">
              <div>
                <label>Start Date of Treatment</label>
              </div>

              <input
                type="date"
                class="form-control"
                placeholder=""
                onChange={(e) => setStartDateofTreatment(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

          <Col md="6">
            <div className="formgroup">
              <div>
                <label>End Date of Treatment</label>
              </div>

              <input
                type="date"
                class="form-control"
                placeholder=""
                onChange={(e) => setEndDateOfTreatment(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Drug caused the ADR</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Drug"
                onChange={(e) => setDrugCausedTheADR(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};
