import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { useDispatch } from "react-redux";
import { setProducts } from "../state/actions/products";

export const SectionSix = () => {
  const [descriptionofCase, setDescriptionofCase] = useState("");
  const [startDateofADR, SetStartDateofADR] = useState("");
  const [endDateofADR, SetEndDateofADR] = useState("");
  const [didtheeventresolve, setDidtheeventresolve] = useState("");
  const [didtheADRreappear, setDidtheADRreappear] = useState("");
  const [didthepatienthadrecived, setDidthepatienthadrecived] = useState("");
  const [treatmentofADR, setTreatmentofADR] = useState("");
  /////////////////////////////////////
  const [next, setNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      const arrData = [
        {
          DescriptionofCase: {
            descriptionofCase,
            startDateofADR,
            endDateofADR,
            didtheeventresolve,
            didtheADRreappear,
            didthepatienthadrecived,
            treatmentofADR,
          },
        },
      ];
      dispatch(setProducts(arrData[0]));
      console.log("Dispatched Data====>", arrData[0]);
    }
    setNext(false);
  }, [next]);
  /////////////////////////////////////

  return (
    <React.Fragment>
      <li class="form-line" style={{ paddingTop: "0px" }}>
        <Row className="">
          <Col md="8">
            <div className="formgroup">
              <div>
                <label>Description of Case</label>
              </div>

              <Form.Control
                as="textarea"
                rows={6}
                onChange={(e) => setDescriptionofCase(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

          <Col md="4">
            <div className="formgroup">
              <div>
                <label>Start Date of ADR</label>
              </div>

              <input
                type="date"
                class="form-control"
                placeholder=""
                onChange={(e) => SetStartDateofADR(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>

            <div className="formgroup">
              <div>
                <label>End Date of ADR</label>
              </div>

              <input
                type="date"
                class="form-control"
                placeholder=""
                onChange={(e) => SetEndDateofADR(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
         
           
          

          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Did the event resolve after Stoping durg? </label>
              </div>

              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions4" 
                    id="inlineRadio9"
                    value="Yes"
                    onChange={(e) => setDidtheeventresolve(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio9">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions4" 
                    id="inlineRadio10"
                    value="No"
                    onChange={(e) => setDidtheeventresolve(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio10">
                    No
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions4" 
                    id="inlineRadio11"
                    value="Didn't Stopped                    "
                    onChange={(e) => setDidtheeventresolve(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio11">
                    Didn't Stopped
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions4" 
                    id="inlineRadio12"
                    value="Not Applicable"
                    onChange={(e) => setDidtheeventresolve(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio12">
                    Not Applicable
                  </label>
                </div>
              </div>
            </div>
          </Col>

          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Did the ADR reappear after reintroduction? </label>
              </div>

              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions5" 
                    id="inlineRadio13"
                    value="Yes"
                    onChange={(e) => setDidtheADRreappear(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio13">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions5" 
                    id="inlineRadio14"
                    value="No"
                    onChange={(e) => setDidtheADRreappear(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio14">
                    No
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions5" 
                    id="inlineRadio15"
                    value="Didn't Stopped"
                    onChange={(e) => setDidtheADRreappear(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio15">
                    Didn't Stopped
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions5" 
                    id="inlineRadio16"
                    value="Not Applicable"
                    onChange={(e) => setDidtheADRreappear(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio16">
                    Not Applicable
                  </label>
                </div>
              </div>
            </div>
          </Col>

          <Col md="12">
            <div className="formgroup">
              <div>
                <label>
                  Did the patient had recived any of the following during the
                  treatment: omeprazole, esomeprazole, fluoxamine, fluoxetine,
                  moclobemide, voriconazole, fluconazole, ticlopidine,
                  carbamazepine and efavirenz?{" "}
                </label>
              </div>

              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions6" 
                    id="inlineRadio17"
                    value="Yes"
                    onChange={(e) => setDidthepatienthadrecived(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio17">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions6" 
                    id="inlineRadio18"
                    value="No"
                    onChange={(e) => setDidthepatienthadrecived(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio18">
                    No
                  </label>
                </div>

                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions6" 
                    id="inlineRadio19"
                    value=" Did n't have the Information"
                    onChange={(e) => setDidthepatienthadrecived(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio19">
                    Did n't have the Information
                  </label>
                </div>
              </div>
            </div>
          </Col>

          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Treatment of ADR (if Required)</label>
              </div>

              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setTreatmentofADR(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};
