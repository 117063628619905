import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { useDispatch } from "react-redux";
import { setProducts } from "../state/actions/products";

export const SectionOneProfInfo = () => {
  const [fullName, setFullName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfReciept, setDateOfReciept] = useState("");
  const [patientTretmentType, setPatientTreatmentType] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportDate, setInitialReportDate] = useState("");
 

  /////////////////////////////////////
  const [next, setNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      const arrData = [
        {
          HealthProfessional: {
            fullName,
            jobTitle,
            phoneNumber,
            officeAddress,
            dateOfReciept,
            patientTretmentType,
            reportType,
            reportDate,
          },
        },
      ];
      dispatch(setProducts(arrData[0]));
      console.log("Dispatched Data====>", arrData[0]);
    }
    setNext(false);
  }, [next]);
  /////////////////////////////////////
  return (
    <React.Fragment>
      <li class="form-line">
        <div className="form-title">
          <h1>Health Professional / Report</h1>
        </div>

        <Row className="">
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Full Name</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: Zeeshan Gazi"
                onChange={(e) => setFullName(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Job Title</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: Doctor"
                onChange={(e) => setJobTitle(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Office Address</label>
              </div>

              <Form.Control
                as="textarea"
                rows={2}
                onChange={(e) => setOfficeAddress(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Phone Number</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: +91 1234567890"
                onChange={(e) => setPhoneNumber(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

          <Col className="col-12">
            <div className="formgroup">
              <div>
                <label>Date of Reciept of Information</label>
              </div>

              <input
                type="date"
                class="form-control"
                placeholder="Eg: +91 1234567890"
                onChange={(e) => setDateOfReciept(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>


          


        </Row>
        <Row className="pt-10">
        <Col md="12">
            <div className="formgroup">
              <div>
                <label>Treatment</label>
              </div>

              <div className="form-group">

 
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions101" 
                    id="inlineRadio101"
                    value="Male"
                    onChange={(e) => setPatientTreatmentType(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio101">
                    Outpatient
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions101" 
                    id="inlineRadio102"
                    value="Female"
                    onChange={(e) => setPatientTreatmentType(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio102">
                   Inpatient
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions101" 
                    id="inlineRadio103"
                    value="Female"
                    onChange={(e) => setReportType(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio103">
                   Self Treatment
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Report</label>
              </div>

              <div className="form-group">

 
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions103" 
                    id="inlineRadio104"
                    value="Male"
                    onChange={(e) => setReportType(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio104">
                    Initial
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions103" 
                    id="inlineRadio105"
                    value="Female"
                    onChange={(e) => setReportType(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio105">
                   Follow Up
                  </label>
                </div>
                
              </div>
            </div>
          </Col>

          <Col className="col-12">
            <div className="formgroup">
              <div>
                <label>Initial Report Date</label>
              </div>

              <input
                type="date"
                class="form-control"
               
                onChange={(e) => setInitialReportDate(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

        </Row>
      </li>
    </React.Fragment>
  );
};
