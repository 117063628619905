import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { SectionOne } from "./SectionOne";
import { SectionTwo } from "./SectionTwo";
import { SectionThree } from "./SectionThree";
import { SectionFour } from "./SectionFour";
import { SectionFive } from "./SectionFive";
import { SectionFiveDrug2 } from "./SectionFiveDrug2";
import { SectionFiveDrug3 } from "./SectionFiveDrug3";
import { SectionSix } from "./SectionSix";
import { SectionSeven } from "./SectionSeven";
import { SectionEight } from "./SectionEight";
import { SectionNine } from "./SectionNine";
import { GenerateXMLFile } from "./GenerateXMLFile";

export const StackedForm = () => {
  return (
    <React.Fragment>
      <Container>
        <form>
          <ul className="clearfix form-wrapper page-section">
            <li class="form-line p-0">
              <div class="form-line-actions">
                <i class="fa fa-close"></i>
              </div>
              <div class="form-header">
                <h1>Stacked Form</h1>
                <h4>
                  Form for reporting of ADR/Lack of efficacy and quality
                  compaliant
                </h4>
              </div>
            </li>
            <SectionOne />
            
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
            <SectionFiveDrug2 />
            <SectionFiveDrug3 />
            <SectionSix />
            <SectionSeven />
            <SectionEight />
            <SectionNine />
            <Container>
              <GenerateXMLFile />
            </Container>
          </ul>
        </form>
      </Container>
    </React.Fragment>
  );
};
