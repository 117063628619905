import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { SectionOnePatientData } from "./SectionOnePatientData";
import { SectionOneProfInfo } from "./SectionOneProfInfo";

export const SectionOne = () => {
  return (
    <React.Fragment>
      <Row className="p-10">
        <Col md="6">
        <SectionOneProfInfo />
        </Col>
        <Col md="6">
        <SectionOnePatientData />
        </Col>
      </Row>
     
     
    </React.Fragment>
  );
};
