import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { useDispatch } from "react-redux";
import { setProducts } from "../state/actions/products";

export const SectionEight = () => {
  const [criteriaforseriousness, setCriteriaforseriousness] = useState("");

  /////////////////////////////////////
  const [next, setNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      const arrData = [
        {
          Criteriaforseriousness: {
            criteriaforseriousness,
          },
        },
      ];
      dispatch(setProducts(arrData[0]));
      console.log("Dispatched Data====>", arrData[0]);
    }
    setNext(false);
  }, [next]);
  /////////////////////////////////////
  return (
    <React.Fragment>
      <li class="form-line">
        <div className="form-title">
          <h1>Criteria for seriousness(specify if applicable):</h1>
        </div>

        <Row className="">
          <div className="col form-group">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions9" 
                    id="inlineRadio27"
                value="death"
                onChange={(e) => setCriteriaforseriousness(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineCheckbox27">
                death
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions9" 
                id="inlineRadio28"
                value="congenital malformations "
                onChange={(e) => setCriteriaforseriousness(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio28">
                congenital malformations
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions9" 
                id="inlineRadio29"
                value="life-threatening condition"
                onChange={(e) => setCriteriaforseriousness(e.target.value)}
                onBlur={() => setNext(!next)}
              />

              <label className="form-check-label" for="inlineRadio29">
                life-threatening condition
              </label>
            </div>

            

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions9" 
                id="inlineRadio31"
                value="inpatient hospitalization or prolongation of existing hospitalization"
                onChange={(e) => setCriteriaforseriousness(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio31">
                inpatient hospitalization or prolongation of existing
                hospitalization
              </label>
            </div>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                    name="inlineRadioOptions9" 
                    id="inlineRadio32"
                value="not applicable"
                onChange={(e) => setCriteriaforseriousness(e.target.value)}
                onBlur={() => setNext(!next)}
              />
              <label className="form-check-label" for="inlineRadio32">
                not applicable
              </label>
            </div>
          </div>
        </Row>
      </li>
    </React.Fragment>
  );
};
