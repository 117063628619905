import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import "../Components/Home.style.css";
import { useDispatch } from "react-redux";
import { setProducts } from "../state/actions/products";

export const SectionOnePatientData = () => {
  const [paitentData, setPaitentData] = useState("");
  const [sex, setSex] = useState("");
  const [age, setAge] = useState("");
  const [intialLetter, setIntialLetter] = useState("");
  const [bodyWeight, setBodyWeight] = useState("");
  const [pregnancy, setPregnancy] = useState("");
  const [hepaticimpairment, setHepaticimpairment] = useState("");
  const [renalimpairment, setRenalimpairment] = useState("");

  /////////////////////////////////////
  const [next, setNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (next) {
      const arrData = [
        {
          PatientInfo: {
            paitentData,
            sex,
            intialLetter,
            age,
            bodyWeight,
            pregnancy,
            hepaticimpairment,
            renalimpairment,
          },
        },
      ];
      dispatch(setProducts(arrData[0]));
      setNext(false);
    }
  }, [next]);
  /////////////////////////////////////

  return (
    <React.Fragment>
      <li class="form-line">
        <div className="form-title">
          <h1></h1>
        </div>
        <Row className="">
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Patient Data</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: Zeeshan Gazi"
                onChange={(e) => setPaitentData(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Initial Letter</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: ASDF"
                onChange={(e) => setIntialLetter(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Sex</label>
              </div>

              <div className="form-group">

 
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions1" 
                    id="inlineRadio1"
                    value="Male"
                    onChange={(e) => setSex(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio1">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions1" 
                    id="inlineRadio2"
                    value="Female"
                    onChange={(e) => setSex(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio2">
                    Female
                  </label>
                </div>
              </div>
            </div>
          </Col>

          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Age</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: 21"
                onChange={(e) => setAge(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>
          <Col md="12">
            <div className="formgroup">
              <div>
                <label>Body Weight</label>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="Eg: 57kg"
                onChange={(e) => setBodyWeight(e.target.value)}
                onBlur={() => setNext(!next)}
              />
            </div>
          </Col>

          <Col className="col-12">
            <div className="formgroup">
              <div>
                <label>Pregnancy</label>
              </div>

              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox3"
                    value="GestationalAge"
                    onChange={(e) => setPregnancy(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineCheckbox3">
                    Gestational age
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-6">
            <div className="formgroup">
              <div>
                <label>Hepatic impairment</label>
              </div>

              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2" 
                    id="inlineRadio3"
                    value="Yes"
                    onChange={(e) => setHepaticimpairment(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio3">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2" 
                    id="inlineRadio4"
                    value="No"
                    onChange={(e) => setHepaticimpairment(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio4">
                    No
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2" 
                    id="inlineRadio5"
                    value="Unknown"
                    onChange={(e) => setHepaticimpairment(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio5">
                    Unknown
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-6">
            <div className="formgroup">
              <div>
                <label>Renal impairment</label>
              </div>

              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3" 
                    id="inlineRadio6"
                    value="Yes"
                    onChange={(e) => setRenalimpairment(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio6">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3" 
                    id="inlineRadio7"
                    value="No"
                    onChange={(e) => setRenalimpairment(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio7">
                    No
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3" 
                    id="inlineRadio8"
                    value="Unknown"
                    onChange={(e) => setRenalimpairment(e.target.value)}
                    onBlur={() => setNext(!next)}
                  />
                  <label className="form-check-label" for="inlineRadio8">
                    Unknown
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </li>
    </React.Fragment>
  );
};
