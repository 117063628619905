import http from "./httpService";
import { apiUrl } from "../config.json";

const apiEndpoint = apiUrl + "api/subscriber";
const apiEndpointCat = apiUrl + "api/subscriber/productcategory";

export function getAllSubscriber(subscriber) {
  return http.post(apiEndpoint + "/all", {
    json: subscriber,
  });
}
export function getLoginSubscriber(subscriber) {
  const apiEndpointLogin = apiEndpoint + "/login";
  return http.post(apiEndpointLogin, {
    json: subscriber,
  });
}

export function getVerifyContactLoginSubscriber(subscriber) {
  const apiEndpointLogin = apiEndpoint + "/login";
  return http.post(apiEndpointLogin, {
    json: subscriber,
  });
}

export function register(subscriber) {
  return http.post(apiEndpoint, {
    json: subscriber,
  });
}

export function getProductCategories(ParentId) {
  return http.post(apiEndpointCat, {
    parentRegistrationNo: ParentId,
  });
}

export function getHomeDisplayProducts(ParentId) {
  return http.post(apiEndpoint + "/displayhomeproducts", {
    parentRegistrationNo: ParentId,
  });
}

export function getSendNotificationEmail(
  ParentId,
  templateCode,
  emailTo,
  nameTo,
  phoneTo,
  messageTo,
  countryTo,
  productTo
) {
  const apiEndpointProd = apiEndpoint + "/sendemail";
  return http.post(apiEndpointProd, {
    parentRegistrationNo: ParentId,
    templateCode: templateCode,
    emailTo: emailTo,
    nameTo: nameTo,
    phoneTo: phoneTo,
    messageTo: messageTo,
    countryTo: countryTo,
    productTo: productTo,
  });
}
export function getGeneratedXMLfromJSON(json) {
  const apiEndpointProd = apiEndpoint + "/generatedatae2b";
  return http.post(apiEndpointProd, json);
}
export function getGeneratedXML(
  ParentId,
  templateCode,
  emailTo,
  nameTo,
  phoneTo,
  messageTo,
  countryTo,
  productTo
) {
  const apiEndpointProd = apiEndpoint + "/generatedatae2b";
  return http.post(apiEndpointProd, {
    parentRegistrationNo: ParentId,
    templateCode: templateCode,
    emailTo: emailTo,
    nameTo: nameTo,
    phoneTo: phoneTo,
    messageTo: messageTo,
    countryTo: countryTo,
    productTo: productTo,
  });
}
export function getProducts(
  productcategoryId,
  registrationno,
  inputSearchText
) {
  const apiEndpointProd = apiUrl + "api/subscriber/products";
  return http.post(apiEndpointProd, {
    productcategoryId: productcategoryId,
    registrationno: registrationno,
    filtSearchText: inputSearchText,
  });
}
